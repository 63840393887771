import { foreachElement, getClickEvent } from './utils';

export function initializeBurger() {
  foreachElement('#header #burger', (button) => {
    button.addEventListener(getClickEvent(), (e) => {
      e.preventDefault();
      document.body.classList.toggle('menu-open');
      return false;
    });
  });
};

