export function initializeAccordion() {
  const accordion = document.querySelector(".accordion");
  if (accordion) {
    accordion.addEventListener("click", (e) => {
      const activePanel = e.target.closest(".accordion-panel");
      if (!activePanel) return;
      toggleAccordion(activePanel);
    });
  }
}

export function initializeModuleAccordion() {
  const moduleAccordionWrapper = document.querySelector(".ce_rsce_events");

  if (moduleAccordionWrapper) {
    // initially set all arias to hidden
    const events = moduleAccordionWrapper.querySelectorAll(".event");
    events.forEach(e => toggleAccordion(e));

    moduleAccordionWrapper.addEventListener("click", (e) => {
      const activePanel = e.target.closest(".event");
      if (!activePanel) return;
      toggleAccordion(activePanel);
    });
  }
}

function toggleAccordion(panelToActivate) {
  const activeButton = panelToActivate.querySelector("button,h1");
  const activePanel = panelToActivate.querySelector(".accordion-content,.ce_text");
  const activePanelIsOpened = activeButton.getAttribute("aria-expanded");

  if (activePanelIsOpened === "false") {
    activeButton.setAttribute("aria-expanded", true);
    activePanel.setAttribute("aria-hidden", false);
  } else {
    activeButton.setAttribute("aria-expanded", false);
    activePanel.setAttribute("aria-hidden", true);
  }
}
